<template>
  <div id="myproduct" class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Tracking Supplier</p>
          </div>
        </div>
        <hr class="m-0 p-0" />
        <div class="row m-0 p-4">
          <div class="col-12 col-xl-5">
            <div class="input-group mb-3">
              <div class="input-group-prepend input-group-sm">
                <select class="custom-select" v-model="mode">
                  <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                    {{ e.name }}
                  </option>
                </select>
              </div>

              <input
                type="search"
                class="form-control  form-control-sm"
                placeholder="ค้นหา / Seach"
                v-model="find"
                v-on:keyup.enter="Search()"
              />
              <div class="input-group-append">
                <button class="btn bt-main btn-sm" @click="Search()">
                  ค้นหา
                </button>
              </div>
            </div>
          </div>
          <div class="col-12">
            <p class="px-3 font-600 w-100" style="display: flex">
              จำนวน&nbsp;
              <span class="text-main">{{
                new Intl.NumberFormat().format(totalRows)
              }}</span>
              &nbsp;รายการ
              <span style="flex: 1; text-align: right; white-space: nowrap;"
                ><button class="btn btn-sm bt-bnb" @click="export_excel">
                  นำออกข้อมูล <span v-html="$svg_icon.excel"></span></button
              ></span>
            </p>
          </div>
          <div class="col-12 ">
            <b-table
              :empty-text="'ไม่พบข้อมูล / No Data'"
              :items="items"
              :fields="fields"
              :current-page="page_num"
              :per-page="page_size"
              outlined
              hover
              show-empty
              responsive
              :tbody-tr-class="rowClass"
              class="font-0-8s "
            >
              <template #cell(รหัสสินค้า)="row">
                <span class="badge badge-main"> {{ row.item.รหัสสินค้า }}</span>
              </template>
              <template #cell(action)="row">
                <span
                  v-html="$svg_icon.view"
                  type="button"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="คลิกเพื่อดูข้อมูลสินค้า"
                  class=" text-main"
                  @click="ViewProduct(row.item.รหัสสินค้า)"
                ></span>
              </template>
              <template #cell(ข้อมูลที่ขาด)="row">
                <div
                  v-for="(value, i) in String(row.item.ข้อมูลที่ขาด).split(',')"
                  :key="i"
                >
                  <div v-if="value !== ''">
                    <span
                      class="badge badge-bnb"
                      v-if="row.item.ข้อมูลที่ตอบกลับ.includes(value)"
                    >
                      {{ value }}
                      <b-icon icon="check-circle-fill"></b-icon>
                    </span>
                    <span class="badge badge-main" v-else-if="value==='ข้อมูลครบถ้วน'">
                      {{ value }}   <b-icon icon="check-all"></b-icon>
                    </span>
                    <span class="badge badge-twd" v-else>
                      {{ value }} <b-icon icon="x-circle-fill"></b-icon>
                    </span>
                  </div>
                </div>
              </template>
              <template #cell(ภาพสินค้าทีขาด)="row">
                <div
                  v-for="(value, i) in String(row.item.ภาพสินค้าทีขาด).split(
                    ','
                  )"
                  :key="i"
                >
                  <div v-if="value !== ''">
                    <span
                      class="badge badge-bnb"
                      v-if="row.item.ภาพที่ตอบกลับ.includes(value)"
                    >
                      {{ value }}
                      <b-icon icon="check-circle-fill"></b-icon>
                    </span> <span class="badge badge-main" v-else-if="value==='ภาพสินค้าครบถ้วน'">
                      {{ value }}   <b-icon icon="check-all"></b-icon>
                    </span>
                    <span class="badge badge-twd" v-else>
                      {{ value }}
                      <b-icon icon="x-circle-fill"></b-icon>
                    </span>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
          <div class="col-12">
            <div class="row justify-content-end">
              <div class="col-6 col-xl-1 input-group-prepend input-group-sm">
                <select class="custom-select" v-model="page_size">
                  <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                    {{ e }}
                  </option>
                </select>
              </div>
              <div class="col-6 col-xl-3">
                <b-pagination
                  v-model="page_num"
                  :total-rows="totalRows"
                  :per-page="page_size"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrackingSupplier",
  data() {
    return {
      find: "",
      page_size: 10,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      totalRows: 0,
      mode: "รหัสสินค้า",
      select_mode: [
        { name: "รหัสสินค้า", key: "รหัสสินค้า" },
        { name: "ชื่อสินค้า", key: "ชื่อสินค้า" },
        { name: "ร้านค้า", key: "ร้านค้า" },
        { name: "วันที่ส่งเมล", key: "วันที่ส่งเมล" },
      ],
      items: [],
      fields: [
        { key: "ลำดับ", label: "ลำดับ" },
        {
          key: "ร้านค้า",
          label: "ร้านค้า",
        },
        { key: "อีเมล", label: "อีเมล" },
        { key: "เบอร์ติดต่อ", label: "เบอร์ติดต่อ", class: "text-left" },
        {
          key: "ผู้ติดต่อ",
          label: "ติดต่อ",
          class: "text-left",
        },
        {
          key: "วันที่ส่งเมล",
          label: "วันที่ส่งเมล",
        },
        { key: "รหัสสินค้า", label: "รหัสสินค้า", class: "text-center" },
        {
          key: "ชื่อสินค้า",
          label: "ชื่อสินค้า",
          class: "text-left",
        },
        {
          key: "ข้อมูลที่ขาด",
          label: "ข้อมูลที่ต้องการ",
          class: "text-left",
        },
        {
          key: "ภาพสินค้าทีขาด",
          label: "ภาพสินค้าทีต้องการ",
          class: "text-left",
        },
        {
          key: "action",
          label: "",
          class: "text-right",
        },
      ],
      items: [],
      itemlist: [],
    };
  },
  watch: {
    find: function() {
      if (this.find == "") {
        this.unSearch();
      }
    },
  },
  computed: {},
  mounted() {
    this.getPimTrackingSupplier();
  },
  methods: {
    ViewProduct(sku) {
      this.$router.push({
        name: "Enrich Product Detail",
        params: { skcode: sku },
      });
    },
    async getPimTrackingSupplier() {
      try {
        var data = {};
        let getAPI = await this.$serviceAPI.call_API(
          "get",
          `report/gettrackingsupplier`,
          data,
          1
        );
        this.items = getAPI.data;
        this.itemlist = getAPI.data;
        this.totalRows = getAPI.data.length;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async export_excel() {
      let json = this.items;
      let name = `Tracking Supplier.xlsx`;
      this.$serviceMain.JsonToExcat(json, name);
      try {
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    Search() {
      var results = [];
      if (this.find == "") {
        this.getPimTrackingSupplier();
      } else {
        let find = this.find;
        if (this.mode !== "active") {
          find = this.find;
        } else {
          find =
            String(this.find).toUpperCase() == "YES"
              ? 1
              : String(this.find).toUpperCase() == "NO"
              ? 0
              : this.find;
        }
        var toSearch = String(find)
          .trim()
          .toLocaleUpperCase()
          .replace(/ /g, "");
        var objects = this.itemlist;
        for (var i = 0; i < objects.length; i++) {
          var index = String(objects[i][this.mode])
            .trim()
            .toLocaleUpperCase()
            .replace(/ /g, "");
          if (index.indexOf(toSearch) != -1) {
            results.push(objects[i]);
          }
        }
        this.items = results;
        this.totalRows = this.items.length;
      }
    },
    unSearch() {
      this.items = this.itemlist;
      this.totalRows = this.items.length;
    },
  },
};
</script>

<style></style>
